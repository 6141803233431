import { useState, useEffect } from 'react'
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native-web';
import { vh, vw } from '../../../../Utilities/Dimensions';
import { useSpring, animated } from 'react-spring'

const AnimatedView = animated(View)

const s = StyleSheet.create({

  numContainer: {
    width: vh(7),
    justifyContent: 'center',
    paddingLeft: vh(1.5)
  },
  numText: {
    textAlign:'center', 
    fontSize: vh(7), 
    fontWeight: 'bold',
    color: 'white'
  },
  albumArtContainer: {
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textContainer: {
    flex:1,
    flexDirection: 'column',
    paddingHorizontal: 10
  },
  voteCountContainer: {
    width: vh(13),
    borderLeftWidth: 2,
    borderColor:'rgba(0,0,0,0.6)',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleContainer: {
    flex:1,
    justifyContent: 'center'
  },
  artistContainer: {
    height: vh(4),
    justifyContent: 'top',
    paddingLeft:1
  },
  titleText: {
    fontSize: vh(6),
    color: 'white'
  },
  artistNameText: {
    fontSize: vh(2),
    color: 'white'
  },
  albumArtImg: {
    width: vh(11.7),
    height: vh(11.7),
    resizeMode: 'contain'
  },
  voteCountText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: vh(2.5)
  },
  btn: {
    //width: '100%',
    //height: '100%'
  }
})

const a = {
  container: {
    //flex:1,
    flexDirection: 'row',
    marginTop: 4,
    borderWidth: 4,
    borderRightWidth: 0,
    borderBottomLeftRadius: 1000,
    borderTopLeftRadius: 1000,
    overflow: 'hidden'
  },

  voteCountContainer: {
    borderRadius: 100,
    overflow: 'hidden',
    width: vh(9),
    height: vh(9),
    alignItems: 'center',
    justifyContent: 'center'
  }
}

const w = {
  isUnknown: 0,
  isMe: 1,
  isNotMe: 2
}

export default ({ selected, isController, isWinner, showWinner, showChoices, showVotes, isFlashing, num, album_url, title, artist_name, vote_count }) => {

  const enterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(100%)'
    },
    to: {
      transform: 'translateX(0%)'
    },
    //reset: enter,
    reverse: ((!showWinner && isWinner == w.isNotMe) || !showChoices) ? true : false,
    delay: (num-1)*100
  })

  const voteRevealAnimation = useSpring({
    from: {
      rotateZ: 0,
      backgroundColor: 'transparent'
    },
    pause: vote_count == null,
    reverse: vote_count != null && !showChoices,
    immediate: vote_count != null && !showChoices,
    to: {
      rotateZ: 360,
      opacity: 1,
      backgroundColor: 'black'
    },
    delay: (num-1)*100
  })

  return (
    <AnimatedView 
      style={{...a.container,
        
        opacity: isController && selected != null && selected != num-1 ? 0.5 : 1,
        ...enterExitAnimation,
        backgroundColor: (isFlashing || isWinner == w.isMe) ? 'gold' :'rgba(0,0,0,0.6)',
        borderColor: isFlashing ? 'gold' : 'black',
        marginBottom: num==5 ? 4 : 0 }}>
        <View style={[s.numContainer, {backgroundColor: (isFlashing || isWinner == w.isMe) ? 'gold' : 'rgba(0,0,0,0.6)'}]}>
          <Text style={[s.numText, {fontSize: isController ? vh(4):vh(7)}]}>{ num }</Text>
        </View>
        <View style={s.albumArtContainer}><Image style={[s.albumArtImg,{width: isController ? vh(8) : vh(10), height: isController ? vh(8) : vh(10)}]} source={ album_url }/></View>
        <View style={s.textContainer}>
          <View style={s.titleContainer}><Text numberOfLines={1} style={[s.titleText,{fontSize: isController ? vh(3): vh(5),color: isWinner == w.isMe ? 'black' : 'white'}]}>{ title }</Text></View>
          <View style={s.artistContainer}><Text numberOfLines={1} style={[s.artistNameText,{color: isWinner == w.isMe ? 'black' : 'white'}]}>{ artist_name }</Text></View>
        </View>
        {!isController && <View style={s.voteCountContainer}>
          <AnimatedView style={{...voteRevealAnimation, ...a.voteCountContainer}}>
            <Text style={[s.voteCountText,{fontSize: vote_count == null ? vh(2.5) : vh(4)} ]}>{vote_count != null ? vote_count : 'VOTE' }</Text>
          </AnimatedView>
        </View>}
    </AnimatedView>
  )
}