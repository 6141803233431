import { View, StyleSheet } from "react-native-web";
import Choices from './Choices'
import CDDisplay from './CDDisplay'

const s = StyleSheet.create({
  container: {
    flex:1,
    //backgroundColor: 'red',
    flexDirection:'row'
  }
}); 

export default () => {
  return ( 
    <View style={s.container}>
      <CDDisplay />
      <Choices />
    </View>
  )
};