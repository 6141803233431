import { useState, useEffect, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import { View, Text, StyleSheet } from 'react-native-web'
import { vh } from '../../../Utilities/Dimensions'
import { GameboardContext, GlobalContext } from '../../../Utilities/Hooks'

const AnimatedView = animated(View)
const AnimatedText = animated(Text)

const s = StyleSheet.create({
  clockContainer: {
    backgroundColor: 'gray',
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  clockText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: vh(8),
    fontVariant: ['tabular-nums']
  },
  timeLeftContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  timeLeftText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: vh(2.5)
  }
})

const a = {
  container: {
    position: 'absolute',
    flex:1,
    right: 0,
    top: vh(5),
    margin: 'auto',
    height: vh(15),
    width: vh(45)
  },
  clockText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: vh(8),
    fontVariant: ['tabular-nums']
  },
  controllerContainer: {
    //position: 'absolute',
    //flex:1,
    //right: 0,
    //bottom:0,
    //margin: 'auto',
    height: vh(8),
    backgroundColor: 'black',
    width: '100%'
  },

}

export default ({ timeLeft, showTimer, isController }) => { 
  const [clock,setClock] = useState('00:00')
  const [label, setLabel] = useState('TIME LEFT')

  const enterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(100%)'
    },
    to: {
      transform: 'translateX(0%)'
    },
    reverse: !showTimer
  })

  const turnRedAnimation = useSpring({
    from: {
      color: 'white'
    },
    to: {
      color: 'red'
    },
    reverse: !(label == 'TIME LEFT' && timeLeft < 36000 && timeLeft > 29000)
  })

  useEffect(() => {
    let _timeLeft = timeLeft
    if(timeLeft >= 25000){
      _timeLeft = Math.max(timeLeft - 30000,0)
      if(label == 'NEXT ROUND'){ setLabel('TIME LEFT')}
    } else if (timeLeft < 25000) {
      if(label == 'TIME LEFT'){ setLabel('NEXT ROUND')}
    }
    let _timeLeftMin = parseInt((_timeLeft/(1000*60))%60)
    let _timeLeftSec = parseInt((_timeLeft/1000)%60)
    _timeLeftMin = ('00' + _timeLeftMin).substr(-2,2)
    _timeLeftSec = ('00' + _timeLeftSec).substr(-2,2)
    setClock(_timeLeftMin + ':' + _timeLeftSec)
  },[timeLeft])

  return (
    <AnimatedView style={{...isController ? a.controllerContainer : a.container,...enterExitAnimation}}>
      <View style={s.timeLeftContainer}><Text style={s.timeLeftText}>{label}</Text></View>
      <View style={s.clockContainer}><AnimatedText style={{...a.clockText,...turnRedAnimation,fontSize: isController ? vh(4) : vh(8)}}>{clock}</AnimatedText></View>  
    </AnimatedView>
  )
}