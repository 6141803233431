import { Outlet } from "react-router-dom"
import { View, Dimensions, StyleSheet } from "react-native-web";
import { useState } from 'react'
import { GlobalContext } from './Utilities/Hooks'

const s = StyleSheet.create({
  container: {
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'yellow',
    overflow: 'hidden'
  }
});


const noVotes = [null,null,null,null,null]
//App.js
export default (props) => {
  const { height, width } = Dimensions.get('window');
  const [songEndTime,setSongEndTime] = useState(0)
  const [choices,setChoices] = useState([])
  const [nowPlaying,setNowPlaying] = useState({})
  const [votes,setVotes] = useState(noVotes)
  const [timeLeft, setTimeLeft] = useState(0)
  const [winner, setWinner] = useState()
  
  const globalVal = {
    songEndTime,setSongEndTime,
    timeLeft,setTimeLeft,
    height, width,
    choices, setChoices,
    nowPlaying,setNowPlaying,
    votes, setVotes,
    winner, setWinner
  }

  return (
      <View style={[s.container,{height:height, width: width}]}>
        <GlobalContext.Provider value={globalVal}>
          <Outlet />
        </GlobalContext.Provider>
      </View>
  );
};
