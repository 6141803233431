import { View, StyleSheet, TouchableOpacity, Text, Image } from "react-native-web";
import { useEffect, useState, useContext } from 'react'
import { authRef } from '../Config/Firebase'
import { vh, vw } from '../Utilities/Dimensions'
import Auth from './Auth'
import Audio from '../Utilities/Audio'
import Server from '../Utilities/Server'
import { HostContext, GlobalContext } from '../Utilities/Hooks'

const s = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    width: 80,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: '#000000',
    backgroundColor: '#C0C0C0',
    borderWidth: 2
  },
  title: {
    color: 'white'
  },
  nowPlayingAlbumArt: {
    height: 100,
    width: 100
  }
});

const track = {
  audioUrl: 'http://www.hyperion-records.co.uk/audiotest/1%20Sullivan%20The%20Lost%20Chord,%20Seated%20one%20day%20at%20the%20organ.MP3',
  albumArtUrl: 'https://okayplayer-wpengine.netdna-ssl.com/wp-content/uploads/2022/05/kendrick-lamar-mr-morale-and-the-big-steppers-album-cover.jpeg',
  seekTo:30000, 
  duration:90000,
  artist: 'Kendrick',
  title: 'Money Trees'
}

export default () => {
  const { songEndTime } = useContext(GlobalContext)

  const [host, setHost] = useState(null)
  const [isStarting, setIsStarting] = useState(false)
  const [isInitializing, setIsInitializing] = useState(true)

  const [startCurrTrack, setStartCurrTrack] = useState(false)
  const [startNextTrack,setStartNextTrack] = useState(false)
  const [currTrack, setCurrTrack] = useState({})
  const [nextTrack, setNextTrack] = useState({})
  const [currTrackUrl, setCurrTrackUrl] = useState()
  const [nextTrackUrl, setNextTrackUrl] = useState()

  const [roundEnded,setRoundEnded] = useState(false)
  const [msg,setMsg] = useState('host mounted')
  const [clock,setClock] = useState('00:00')
  const [timeLeft,setTimeLeft] = useState(0)
  
  const hostVal = {
    host, setHost,
    msg,setMsg,
    isInitializing,setIsInitializing,
    isStarting, setIsStarting,
    startCurrTrack,setStartCurrTrack,
    startNextTrack,setStartNextTrack,
    currTrack, setCurrTrack,
    nextTrack, setNextTrack,
    currTrackUrl, setCurrTrackUrl,
    nextTrackUrl, setNextTrackUrl,
    roundEnded,setRoundEnded
  }

  const handleOnAuthPress = e => {
    authRef.signOut()
  }
  
  const handleOnStartPress = e => {
    setIsStarting(true)
  }

  useEffect(() => {
    authRef.onAuthStateChanged(_host => {
      if(_host){
        setHost(_host)
      } else {
        setHost(null)
      }
    })
  },[])

  useEffect(() => {
    let _timeLeftMin = parseInt((timeLeft/(1000*60))%60)
    let _timeLeftSec = parseInt((timeLeft/1000)%60)
    _timeLeftMin = ('00' + _timeLeftMin).substr(-2,2)
    _timeLeftSec = ('00' + _timeLeftSec).substr(-2,2)
    setClock(_timeLeftMin + ':' + _timeLeftSec)
  },[timeLeft])

   //Clock Driver
  const refreshClock = () => {
    let _timeLeft = Math.floor(Math.max(songEndTime - Date.now(),0))
    setTimeLeft(_timeLeft)
  }

  useEffect(() => {
    const timeout = setTimeout(refreshClock,100)
    return () => clearTimeout(timeout)
  },[songEndTime,timeLeft])

  if(host){
    return (
      <View style={s.container}>
        <HostContext.Provider value={hostVal}>
          <Server />
          <Audio />
          <View style={s.nowPlayingAlbumArt}>
              <Image style={{height:100,width:100}} source={currTrack.albumArtUrl} />
          </View>
          <View style={s.titleCtr}><Text style={s.title}>{currTrack.title}</Text></View>
          <View style={s.artistCtr}><Text style={s.title}>{currTrack.artist}</Text></View>
          <View style={s.clockCtr}><Text style={s.title}>{clock}</Text></View>
          {!isStarting && <TouchableOpacity
            style={[s.button,{ marginTop: vh('3%') }]}
            onPress={handleOnStartPress}>
            <Text>Start</Text>
          </TouchableOpacity>}
          <TouchableOpacity
            style={[s.button,{ marginTop: vh('3%') }]}
            onPress={handleOnAuthPress}>
            <Text>Sign Out</Text>
          </TouchableOpacity>
          <Text style={{color: 'lightblue'}}>{msg}</Text>
        </HostContext.Provider>
      </View>
    )
  } else {
    return <Auth />
  }
};
