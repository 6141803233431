import { View, Text, StyleSheet, Image } from 'react-native-web'
import { useContext } from 'react'
import { useSpring, animated, config } from 'react-spring'
import { GameboardContext, GlobalContext } from '../../Utilities/Hooks'
import { vh } from '../../Utilities/Dimensions'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  logoImg: {
    height: vh(24),
    width: vh(100),
    resizeMode: 'contain'
  }
})

const a = {
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: vh(3),
    margin: 'auto',
    height: vh(24),
    width: vh(100)
  }
}

const logoSrc = 'https://cdn.freebiesupply.com/logos/large/2x/batman-5-logo-png-transparent.png'
//const logoSrc = 'https://okayplayer-wpengine.netdna-ssl.com/wp-content/uploads/2022/05/kendrick-lamar-mr-morale-and-the-big-steppers-album-cover.jpeg'

export default () => {
  const { hide } = useContext(GameboardContext)

  const enterExitAnimation = useSpring({
    from: {
      transform: 'translateY(0%)'
    },
    to: {
      transform: 'translateY(-200%)'
    },
    reverse:!hide,
    config: config.molasses
  })

  return (
    <AnimatedView style={{ ...a.container,...enterExitAnimation}}>
        <Image style={s.logoImg} source={ logoSrc }/>
    </AnimatedView>
  )
}