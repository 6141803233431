import { View, Text, StyleSheet } from 'react-native-web'
import { useState, useEffect, useContext } from 'react'
import { useSpring, animated, config } from 'react-spring'
import { vh } from '../../../Utilities/Dimensions'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    //opacity: 1,
    backgroundColor: 'blue'
  },
  countdownNum: {
    fontSize: vh(100),
    fontWeight: 'bold',
    color: 'white'
  }
})

const a = {
  backgroudContainer:{
    position:'absolute',
    backgroundColor: 'black',
    width: '100%',
    width: '100%',
    height: '100%'//,
    //opacity: 0
  },
  numberContainer: {
    position:'absolute',
    width: '100%',
    height: '100%'
  }
}
export default () => {
  const [open,setOpen] = useState(false)
  
  const backgroundAnimation = useSpring({
    from: { opacity:0 },
    to: { opacity:1 },
    //reset: true,
    reverse: !open,
    onRest: () => {
      if(open){ setOpen(!open) }
    },
    config:{
      duration: 100
    }
  })

  const numberAnimation = useSpring({

  })

  /*useEffect(() => {
    let timer
    if(!open){
      timer = setTimeout(() => {
        //console.log('open')
        setOpen(!open)
      }, 800)
    }
    return () => clearTimeout(timer)
  },[open])*/

  return (
    <View style={s.container}>
      <AnimatedView style={{ ...a.backgroudContainer}}>
        <Text style={s.countdownNum}>1</Text>
      </AnimatedView>
    </View>
  )
}