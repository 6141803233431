import { View, StyleSheet } from "react-native-web";
import { useContext, useState, useEffect } from 'react'
import { streamRef } from '../../Config/Firebase'
import BackgroundVisual from '../Gameboard//BackgroundVisual'
import CDDisplay from '../Gameboard/MiddleArea/CDDisplay'
import Choices from '../Gameboard/MiddleArea/Choices'
import Timer from '../Gameboard/TopBar/Timer'
import WinnerCover from '../Gameboard/TopBar/WinnerCover'
import NowPlaying from '../Gameboard/BottomBar/NowPlaying'
import Menu from './Menu'
import PopUp from './PopUp'
import { GameboardContext, GlobalContext } from '../../Utilities/Hooks'
import { vh } from '../../Utilities/Dimensions'
import VoteCircle from "../Gameboard/VoteCircleOverlay/VoteCircle";

const s = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    alignItems: 'center'
  },
  innerContainer: {
    maxWidth: 360,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    //backgroundColor: 'orange',
    //flex:1
    paddingTop: 25
  },
  timerContainer: {
    width: '100%',
    //height: vh(10),
    //backgroundColor: 'purple'
  },
  topBar: {
    position: 'absolute',
    flexDirection: 'row-reverse',
    width:'100%',
    padding: 10
  },
  bottomContainer: {
    position: 'absolute',
    bottom:0,
    width: '100%',
    //justifyContent: 'end',
    //flexDirection: 'column-reverse',
    //backgroundColor: 'yellow'
  }
});

export default () => {
  const { songEndTime, setSongEndTime, setChoices, nowPlaying, setNowPlaying, setWinner } = useContext(GlobalContext)
  
  const [minimize,setMinimize] = useState(false)
  const [showWinner,setShowWinner] = useState(false)
  const [showWinningChoice,setShowWinningChoice] = useState(false)
  const [showTimer,setShowTimer] = useState(true)
  const [showLogo,setShowLogo] = useState(true)
  const [showCD,setShowCD] = useState(true)
  const [showNoise,setShowNoise] = useState(false)
  const [showCountdown,setShowCountdown] = useState(true)
  const [showChoices,setShowChoices] = useState(true)
  const [timeLeft,setTimeLeft] = useState(0)
  const [votesEnded,setVotesEnded] = useState(false)
  const [roundRestart,setRoundRestart] = useState(false)

  const gameboardValues = {
    minimize,setMinimize,
    showWinner,setShowWinner,
    showTimer,setShowTimer,
    showLogo, setShowLogo,
    showCD, setShowCD,
    showNoise, setShowNoise,
    showCountdown,setShowCountdown,
    showChoices,setShowChoices,
    timeLeft,setTimeLeft,
    votesEnded,setVotesEnded,
    showWinningChoice,setShowWinningChoice,
    roundRestart, setRoundRestart
  }

  const refreshClock = () => {
    let _timeLeft = Math.max(songEndTime - Date.now(),0)
    setTimeLeft(_timeLeft)
  }

  useEffect(() => {
    const interval = setInterval(refreshClock,100)
    return () => clearInterval(interval)
  },[songEndTime])

  useEffect(() => {
    if(timeLeft == 0){
      setSongEndTime(Date.now() + 45000)
    }
  },[timeLeft])

  useEffect(() => {
    if(timeLeft > 30000 && roundRestart){
      setShowWinner(false)
      setShowWinningChoice(false)
      setShowTimer(true)
      setShowLogo(true)
      setShowCD(true)
      setShowNoise(false)
      setShowCountdown(false)
      setShowChoices(true)
      setVotesEnded(false)
      setRoundRestart(false)
    }
  },[timeLeft,roundRestart])

  useEffect(() => {
    if(!showWinner && !votesEnded && timeLeft <= 31000 && timeLeft > 0){
      setShowWinner(true)
      setVotesEnded(true)
    } else if (showWinner && timeLeft < 15000) {
      setShowWinner(false)
    } else if (!showWinner && votesEnded && timeLeft >= 31000) {
      setShowTimer(true)
      setVotesEnded(false)
    }

    if(timeLeft < 1500 && timeLeft > 0 && showTimer){
      setShowTimer(false)
      setRoundRestart(true)
    }
  },[timeLeft, showTimer,showWinner, votesEnded])
  //Listeners
  useEffect(() => {
    streamRef.child('fridaybeers/public').on('value', snapshot => {
      let data = snapshot.val()
      if(data){
        console.log('DATA - disply')
        console.log(data)
        setNowPlaying(data.nowPlaying)
        setSongEndTime(data.songEndTime)
        setChoices(data.choices)
        setWinner(data.winner)
      }
    })
    return () => {
      streamRef.child('fridaybeers/public').off()
    }
  },[])
  return (
    <View style={s.container}>
      <GameboardContext.Provider value={gameboardValues}>
        <BackgroundVisual />
        <View style={s.topBar}>
          
          {/*<VoteCircle />*/}
        </View>
        
        <View style={s.innerContainer}>
          <CDDisplay isController={true}/>
          <View style={s.nowPlaying}></View>
          <View style={s.bottomContainer}>
            
            <View style={s.timerContainer}>
              <Timer isController={true} showTimer={true} timeLeft={timeLeft}/>
              <WinnerCover isController={true} showWinner={showWinner}/>
            </View>
            
          </View>
          <View style={{height:60}} />
          <Choices isController={true}/>
          <PopUp />
          <Menu />
        </View>
      </GameboardContext.Provider>
    </View>
  )
};
