import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

import App from "./App";
import { 
  Audience, 
  Display, 
  Host
} from "./Screens";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Audience />} />
          <Route path="display" element={<Display />} />
          <Route path="host" element={<Host />} />
        </Route>
      </Routes>
    </BrowserRouter> 
  </StrictMode>
);
