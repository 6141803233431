import { View, StyleSheet } from "react-native-web";
import { useState, useEffect, useContext } from 'react'
import BottomBar from './BottomBar'
import TopBar from './TopBar'
import BackgroundVisual from './BackgroundVisual'
import MiddleArea from './MiddleArea'
import Logo from './Logo'
import defaultBGimg from '../../Assets/placeholderBGimg.png'
import VoteCircleOverlay from './VoteCircleOverlay'
import Overlays from './Overlays'
import { GameboardContext, GlobalContext } from '../../Utilities/Hooks'
import { streamRef } from '../../Config/Firebase'

const s = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: 'blue'
  }
});

export default () => {
  const { songEndTime, setSongEndTime, setChoices, nowPlaying, setNowPlaying, setWinner } = useContext(GlobalContext)
  const [minimize,setMinimize] = useState(false)
  const [showWinner,setShowWinner] = useState(false)
  const [showWinningChoice,setShowWinningChoice] = useState(false)
  const [showTimer,setShowTimer] = useState(true)
  const [showLogo,setShowLogo] = useState(true)
  const [showCD,setShowCD] = useState(true)
  const [showNoise,setShowNoise] = useState(false)
  const [showCountdown,setShowCountdown] = useState(true)
  const [showChoices,setShowChoices] = useState(true)
  const [timeLeft,setTimeLeft] = useState(0)
  const [votesEnded,setVotesEnded] = useState(false)
  const [roundRestart,setRoundRestart] = useState(false)

  const gameboardValues = {
    minimize,setMinimize,
    showWinner,setShowWinner,
    showTimer,setShowTimer,
    showLogo, setShowLogo,
    showCD, setShowCD,
    showNoise, setShowNoise,
    showCountdown,setShowCountdown,
    showChoices,setShowChoices,
    timeLeft,setTimeLeft,
    votesEnded,setVotesEnded,
    showWinningChoice,setShowWinningChoice,
    roundRestart, setRoundRestart
  }

  const refreshClock = () => {
    let _timeLeft = Math.max(songEndTime - Date.now(),0)
    setTimeLeft(_timeLeft)
  }

  useEffect(() => {
    const interval = setInterval(refreshClock,100)
    return () => clearInterval(interval)
  },[songEndTime])

  useEffect(() => {
    if(timeLeft == 0){
      setSongEndTime(Date.now() + 45000)
    }
  },[timeLeft])

  useEffect(() => {
    if(timeLeft > 30000 && roundRestart){
      setShowWinner(false)
      setShowWinningChoice(false)
      setShowTimer(true)
      setShowLogo(true)
      setShowCD(true)
      setShowNoise(false)
      setShowCountdown(false)
      setShowChoices(true)
      setVotesEnded(false)
      setRoundRestart(false)
    }
  },[timeLeft,roundRestart])

  //Listeners
  useEffect(() => {
    streamRef.child('fridaybeers/public').on('value', snapshot => {
      let data = snapshot.val()
      if(data){
        console.log('DATA - disply')
        console.log(data)
        setNowPlaying(data.nowPlaying)
        setSongEndTime(data.songEndTime)
        setChoices(data.choices)
        setWinner(data.winner)
      }
    })
    return () => {
      streamRef.child('fridaybeers/public').off()
    }
  },[])

  return (
    <GameboardContext.Provider value={gameboardValues}>
      <View style={s.container}>
        <BackgroundVisual />
        <TopBar />
        <MiddleArea />
        <BottomBar />
        <Logo />
        <Overlays />
        <VoteCircleOverlay />
      </View>
    </GameboardContext.Provider>
  )
}