//import { initializeApp } from 'firebase/app';
//import { getAuth } from 'firebase/auth'
import firebase from 'firebase/app';
import 'firebase/auth'
import "firebase/database"
import "firebase/storage"

const FirebaseConfig = {
  apiKey: "AIzaSyC_FW72ZgmtO0VVg85DnNu4hVrezb3uPk4",
  authDomain: "fluid-unfolding-125308.firebaseapp.com",
  databaseURL: "https://fluid-unfolding-125308.firebaseio.com",
  projectId: "fluid-unfolding-125308",
  storageBucket: "fluid-unfolding-125308.appspot.com",
  messagingSenderId: "106851368111"
}

const app = firebase.initializeApp(FirebaseConfig);

export const authRef = firebase.auth(app);

//Realtime Database

export const playlistRef = firebase.database().ref().child("djPlaylists-business");
export const streamRef = firebase.database().ref().child("streamV0");
export const songRef = firebase.database().ref().child("songsV7");
export const businessRef = firebase.database().ref().child("business-assets");


//Storage
export var storageRef = firebase.storage().ref();
export var audioRef = firebase.storage().ref().child("sparkLib/audioFiles");
