import CountdownSequence from "./CountdownSequence"

import { View, Text, StyleSheet } from 'react-native-web'
import { useState, useContext } from 'react'
import { useSpring } from 'react-spring'
import { vh } from '../../../Utilities/Dimensions'
import { GameboardContext } from '../../../Utilities/Hooks'

const s = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
})

export default () => {
  const { showCountDown } = useContext(GameboardContext)
  return (
    <View style={s.container}>
      {showCountDown && <CountdownSequence />}
    </View>
  )
}