import { Image, View, Text, StyleSheet } from 'react-native-web'
import { useEffect, useState } from 'react'
import { vw,vh } from '../../../Utilities/Dimensions'
import { useSpring, animated, config } from 'react-spring'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  initialsContainer: {
    marginHorizontal: 10
  },
  initialsText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: vh(6.5)
  },
  avatarContainer: {
    borderRadius:100,
    backgroundColor: 'yellow',
    height: vh(8),
    width: vh(8),
    overflow: 'hidden'
  },
  avatarImg: {
    height: vh(8),
    width: vh(8)
  }
})

const a = {
  container: {
    position: 'absolute',
    backgroundColor: 'blue',
    borderRadius: 100,
    flex:1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderColor: 'white',
    borderWidth: 3
  }
}


export default ({ userInitials, color, avatarUrl, voteID, votes, setVotes }) => {
  const [open,setOpen] = useState(true)
  const [posX,setPosX] = useState(vw(10+80*Math.random()))
  const [posY,setPosY] = useState(vh(10+80*Math.random()))

  const unmountVoteCircle = () => {
    let _votes = votes
    _votes[voteID].unmount = true
    setVotes(_votes)
  }

  const enterExitAnimation  = useSpring({
    from: {
      transform: 'scale(0%)'
    },
    to: {
      transform: 'scale(100%)'
    },
    config: open ? config.wobbly : config.default,
    reverse: !open,
    onRest: () => {
      if(!open){ unmountVoteCircle() }
    }
  })

  useEffect(() => {
    let timer = setTimeout(()=> {
      setOpen(false)
    },2000)
    return ()=>clearTimeout(timer)
  },[])

  return (
    <AnimatedView style={{...a.container, ...enterExitAnimation,top:posY,left:posX, backgroundColor: color}}>
      <View style={s.avatarContainer}><Image style={s.avatarImg} source={avatarUrl}/></View>
      <View style={s.initialsContainer}><Text style={s.initialsText}>{userInitials}</Text></View>
    </AnimatedView>
  )
}