
import { useEffect, useState, useContext } from 'react'
import { View, TouchableOpacity } from 'react-native-web'
import { useSpring, animated } from 'react-spring'
import { vw, vh } from '../../../../Utilities/Dimensions'
import { GameboardContext, GlobalContext } from '../../../../Utilities/Hooks'
import { streamRef } from '../../../../Config/Firebase'
import Choice from './Choice'

const AnimatedView = animated(View)

const a = {
  container: {
    //backgroundColor: 'yellow',
    width: vw(60)
  },
  controllerContainer: {
    width: '100%'
  }
}

let album_url = 'https://okayplayer-wpengine.netdna-ssl.com/wp-content/uploads/2022/05/kendrick-lamar-mr-morale-and-the-big-steppers-album-cover.jpeg'
let title = 'Track Title'
let artist_name = 'Artist Name'
let vote_count = 10
let userID = 'v3fv2vf'

const w = {
  isUnknown: 0,
  isMe: 1,
  isNotMe: 2
}

const flashersOff = [false,false,false,false,false]
const unknownWinner = [w.isUnknown,w.isUnknown,w.isUnknown,w.isUnknown,w.isUnknown]
const knownWinner = [w.isNotMe,w.isNotMe,w.isNotMe,w.isNotMe,w.isNotMe]


export default ({ isController }) => {
  const { minimize, showWinner, timeLeft, showWinningChoice, setShowWinningChoice, showTimer, votesEnded } = useContext(GameboardContext)
  const { user, votes, setVotes, choices, winner } = useContext(GlobalContext)
  
  const [flashArray,setFlashArray] = useState(flashersOff)
  const [isFlashing, setIsFlashing] = useState(false)
  const [winnerArray,setWinnerArray] = useState(unknownWinner)
  const [x,setX] = useState(0)
  const [selected,setSelected] = useState(null)
  const [tally, setTally] = useState([0,0,0,0,0])

  const enterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(85%)'
    },
    to: {
      transform: 'translateX(0%)'
    },
    //reset: !hide,
    reverse: minimize,
  })

  useEffect(() => {
    if(!isFlashing && !showWinningChoice && timeLeft < 30500 && timeLeft > 0 && !isController) {
      setIsFlashing(true)
    } else if (isFlashing && votesEnded && timeLeft < 20000 && !isController) {
      setIsFlashing(false)
    } else if (votesEnded && timeLeft < 1000){
      setWinnerArray(unknownWinner)
      setVotes([null,null,null,null,null])
      setSelected(null)
    }
  },[timeLeft])

  useEffect(() => {
    let timer
    if(isFlashing){
      let num
      timer = setTimeout(() => {
        num = Math.floor(Math.random()*5)
        num = num == flashArray.indexOf(true) ? (num == 4 ? 0 : num+1) : num
        let flashersOn = [...flashersOff]
        flashersOn[num] = true
        setX(_x => _x+0.1)
        setFlashArray(flashersOn)
        if(x > 6){
          setIsFlashing(false)
          setX(0)
          setShowWinningChoice(true)
          let _winnerArray = [...knownWinner]
          _winnerArray[winner] = w.isMe
          setWinnerArray(_winnerArray)
          let _votes = JSON.parse(JSON.stringify(tally))
          setVotes(_votes)
        }
      },20 +  1*Math.exp(x))
    } else if (!isFlashing || x > 4.2) {
      setFlashArray(flashersOff)
    }
    return () => clearTimeout(timer)
  },[isFlashing,flashArray,x])

  const handleOnPress = i => {
    console.log('selected')
    console.log(i)
    setSelected(i)
    streamRef.child('fridaybeers/private/votes/'+ userID).set({
      userID: userID,//user.uid,
      timestamp: Date.now(),
      userInitials: 'TST',
      color: 'orange',
      avatarUrl: 'https://www.w3schools.com/howto/img_avatar2.png',
      selected: i
    })
  }

  useEffect(() => {
    let ref = streamRef.child('fridaybeers/private/votes/'+ userID)
    ref.on('value', snapshot => {
      let data = snapshot.val()
      if(data){
        if(data.selected){ setSelected(data.selected) }
      }
    })
    return () => ref.off()
  },[])

  useEffect(() => {
    let ref = streamRef.child('fridaybeers/private/tally')
    ref.on('value', snapshot => {
      let data = snapshot.val()
      if(data){ setTally(data)}
    })
    return () => ref.off()
  },[])

  return (
    <AnimatedView style={{ ...isController ? a.controllerContainer : a.container,...enterExitAnimation }}>
      {
        choices.map((choice,i) => {
          return (
            <TouchableOpacity key={i} onPress={() => handleOnPress(i)} disabled={selected != null || votesEnded || !isController}>
              <Choice key={i} isController={isController} selected={selected} num={i+1} showWinner={showWinner} showWinner={showWinner} showChoices={showTimer} isFlashing={flashArray[i]} isWinner={winnerArray[i]} album_url={choice.albumArtUrl} title={choice.title} artist_name={choice.artist} vote_count={votes[i]}/>
            </TouchableOpacity>
          )
        })
      }
    </AnimatedView>
  )
}