import { View, Text, Dimensions, StyleSheet } from 'react-native-web'
import { useState, useEffect } from 'react'
import { useSpring, animated, config } from 'react-spring'
import { vw, vh } from '../../../Utilities/Dimensions'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  container: {
    height: vh(10),
    width: '100%',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: 'rgba(0,0,0,0.85)'
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontSize: vh(5.18)
  }
})

const defaultText = [
  'Vote on the music for FREE!',
  'Go to fridaybeers.sparkdj.com'
]

const a = {
  textContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: "center",
    justifyContent: "center"
  }
}

export default ({ text=defaultText}) => {
  const [currMessage,setCurrMessage] = useState(text[0])
  const [nextMessage,setNextMessage] = useState(text[1])
  const [messageIndex, setMessageIndex] = useState(1)
  const [pauseCurr, setPauseCurr] = useState(false)
  const [pauseNext, setPauseNext] = useState(false)
  
  const currEnterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(0%)'
    },
    to: {
      transform: 'translateX(-100%)'
    },
    loop: !pauseCurr,
    pause: pauseCurr,
    config: config.molasses,
    onRest: () => {
      
      setPauseCurr(true)

    }
  })
  
  const nextEnterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(100%)'
    },
    to: {
      transform: 'translateX(0%)'
    },
    loop: !pauseNext,
    config: config.molasses,
    onRest: () => {
      setPauseNext(true)
    },
    pause: pauseNext
  })
  
  useEffect(() => {
    let timer
    
    if(pauseNext){
      timer = setTimeout(() => {
        setPauseNext(false)
        if(messageIndex + 1 == text.length){ 
          setNextMessage(text[0])
          setMessageIndex(0)
        } else {
          setNextMessage(text[messageIndex+1])
          setMessageIndex(_messageIndex => _messageIndex+1)
        }
      },7000)
    }
    return () => clearTimeout(timer)
  },[pauseNext])

  useEffect(() => {
    let timer
    
    if(pauseCurr){
      setCurrMessage(nextMessage)
      timer = setTimeout(() => {
        setPauseCurr(false)
        
      },7000)
    }
    return () => clearTimeout(timer)
  },[pauseNext])

  return (
    <View style={s.container}>
        <AnimatedView style={{...a.textContainer,...currEnterExitAnimation}}><Text style={s.text}>{currMessage}</Text></AnimatedView>
        <AnimatedView style={{...a.textContainer,...nextEnterExitAnimation}}><Text style={s.text}>{nextMessage}</Text></AnimatedView>
    </View>
  )
}