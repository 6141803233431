import { View, StyleSheet, TextInput, TouchableOpacity, Text, Image } from "react-native-web";
import { useState } from 'react'
import { authRef } from '../Config/Firebase'
import { vh, vw } from '../Utilities/Dimensions'
//var firebaseui = require('firebaseui')
//var firebase = require('firebase/auth')

const s = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "black"
  },
	logo: {
		height: vh('27.42%'),
		width: vw('38.75%')
	},
	loginContainer: {
		alignItems: 'center',
		marginTop: 'auto',
		marginBottom: 'auto'
	},
	textbox: {
		width: vw('45%'),
		height: vh('10%'),
		borderRadius: vw('0.7%'),
		backgroundColor: 'gray',
		borderColor: 'gray',
		borderWidth: 1,
	},
	errorMeg: {
		color: 'white',
		fontSize:vw('2%'),
		marginTop:vh('4%')
  },
  button: {
    width: 80,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: '#000000',
    backgroundColor: '#C0C0C0',
    borderWidth: 2
  }
});

const logoSrc = 'https://cdn.freebiesupply.com/logos/large/2x/batman-5-logo-png-transparent.png'

export default () => {
  const [email,setEmail] = useState()
  const [password,setPassword] = useState()

  const handleOnPress = (_email,_password) => {
    authRef.signInWithEmailAndPassword(_email,_password)
    console.log('PRESSED')
  }

  return (
    <View style={s.container}>
      <View style={s.loginContainer}>
        <Image style={s.logo} source={ logoSrc } />
        <TextInput
          placeholder="Email Address"
          onChangeText={(text) => setEmail(text) }
          placeholderTextColor='white'
          style={[s.textbox, { marginTop: vh('5%'), fontSize: vw('2%') }]}
        />
        <TextInput
          placeholder="Password"
          placeholderTextColor='white'
          onChangeText={text => setPassword(text)}
          style={[s.textbox, { marginTop: vh('3%'), fontSize: vw('2%') }]}
          secureTextEntry={true}
        />
        <TouchableOpacity
          style={[s.button,{ marginTop: vh('3%') }]}
          onPress={() => handleOnPress(email,password)}>
          <Text>Sign In</Text>
        </TouchableOpacity>
        <Text style={s.errorMeg}>{'Error Message'}</Text>
      </View>
    </View>
  );
};
