import { View, Text, StyleSheet } from 'react-native-web'
import { useContext, useState, useEffect } from 'react'
import { GameboardContext, GlobalContext } from '../../../Utilities/Hooks'
import { vh } from '../../../Utilities/Dimensions'
import Timer from './Timer'
import LittleTimer from './LittleTimer'
import WinnerCover from './WinnerCover'
import LittleWinnerCover from './LittleWinnerCover'

const s = StyleSheet.create({
  container: {
    width: '100%',
    height: vh(30)
  }
})

export default () => {
  const { showTimer, setShowTimer, showWinner, setShowWinner, minimize } = useContext(GameboardContext)
  const { timeLeft, votesEnded, setVotesEnded, setRoundRestart } = useContext(GameboardContext)
  const { setVotes } = useContext(GlobalContext)

  useEffect(() => {
    if(!showWinner && !votesEnded && timeLeft <= 31000 && timeLeft > 0){
      setShowWinner(true)
      setVotesEnded(true)
    } else if (showWinner && timeLeft < 15000) {
      setShowWinner(false)
    } else if (!showWinner && votesEnded && timeLeft >= 31000) {
      setShowTimer(true)
      setVotesEnded(false)
    }

    if(timeLeft < 1500 && timeLeft > 0 && showTimer){
      setShowTimer(false)
      setRoundRestart(true)
    }
  },[timeLeft, showTimer,showWinner, votesEnded])

  return (
    <View style={s.container}>
      <LittleTimer showTimer={minimize && showTimer} timeLeft={timeLeft}/>
      <LittleWinnerCover showWinner={minimize && showWinner} />
      <Timer showTimer={!minimize && showTimer} timeLeft={timeLeft}/>
      <WinnerCover showWinner={!minimize && showWinner} />
    </View>
  )
}