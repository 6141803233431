import { View } from 'react-native-web'
import { useState, useContext, useEffect, useRef } from "react";
import ReactHowler from 'react-howler'
import { HostContext, GlobalContext } from './Hooks'
import silence from '../Assets/silence_64kb.mp3'

const copy = obj => JSON.parse(JSON.stringify(obj))

export default () => {
  const {
    isInitializing, setIsInitializing,
    roundEnded, setRoundEnded,
    currTrack, currTrackUrl, setCurrTrack, startCurrTrack, setStartCurrTrack,
    nextTrack, nextTrackUrl, setNextTrack, startNextTrack, setStartNextTrack
  } = useContext(HostContext)
  
  const {
    songEndTime, setSongEndTime,
    
  } = useContext(GlobalContext)

  const deckA = useRef()
  const [trackA,setTrackA] = useState({})
  const [playDeckA, setPlayDeckA] = useState(false)
  const [seekDeckA, setSeekDeckA] = useState(0)
  const [trackAUrl, setTrackAUrl] = useState()
  const [trackAVol, setTrackAVol] = useState(1)

  const deckB = useRef()
  const [trackB,setTrackB] = useState({})
  const [playDeckB, setPlayDeckB] = useState(false)
  const [seekDeckB, setSeekDeckB] = useState(0)
  const [trackBUrl, setTrackBUrl] = useState()
  const [trackBVol, setTrackBVol] = useState(1)

  const [currIsB,setCurrIsB] = useState(false)
  const [timeLeft,setTimeLeft] = useState(0)
  const [tic, setTic] = useState(false)

  useEffect(() => {
    if(deckA.current && seekDeckA > 0){
      console.log('deckA')
      console.log(deckA.current)
      deckA.current.seek(seekDeckA)
    }
  },[seekDeckA])

  useEffect(() => {
    if(deckB.current && seekDeckB > 0){
      console.log(deckB)
      deckB.current.seek(seekDeckB)
    }
  },[seekDeckB])

  useEffect(() => {
    if(startNextTrack){
      console.log('starting next track')
      console.log('currIsB: ' + currIsB)
      currIsB ? setPlayDeckA(true) : setPlayDeckB(true)
      setStartNextTrack(false)
    }
  },[startNextTrack])

  useEffect(() => {
    if(startCurrTrack){
      console.log('starting current track')
      console.log('currIsB: ' + currIsB)
      currIsB ? setPlayDeckB(true) : setPlayDeckA(true)
      setStartCurrTrack(false)
    }
  },[startCurrTrack])

  useEffect(() => {
    if(nextTrack && nextTrack.songID){
      currIsB ? setTrackA(copy(nextTrack)) : setTrackB(copy(nextTrack))
      console.log('currIsB: ' + currIsB)
      console.log('next track')
      console.log(nextTrack)
    }
  },[nextTrack])

  useEffect(() => {
    if(nextTrackUrl){
      currIsB ? setTrackAUrl(nextTrackUrl) : setTrackBUrl(nextTrackUrl)
      console.log('currIsB: ' + currIsB)
      console.log('nextTrackUrl')
      console.log(nextTrackUrl)
    }
  },[nextTrackUrl])

  useEffect(() => {
    if(currTrack && currTrack.songID){
      currIsB ? setTrackB(copy(currTrack)) : setTrackA(copy(currTrack))
      console.log('currIsB: ' + currIsB)
      console.log('currTrack')
      console.log(currTrack)
    }
  },[currTrack])

  useEffect(() => {
    if(currTrackUrl){
      currIsB ? setTrackBUrl(currTrackUrl) : setTrackAUrl(currTrackUrl)
      console.log('currIsB: ' + currIsB)
      console.log('currTrackUrl')
      console.log(currTrackUrl)
    }
  },[currTrackUrl])

  //Clock Driver
  const refreshClock = () => {
    //let _timeLeft = Math.max(songEndTime - Date.now(),0)
    //setTimeLeft(_timeLeft)
    let deck = currIsB ? deckB : deckA
    setTic(toc => !toc)
    if(deck.current){
      let _timeLeft = Math.floor(Math.max((deck.current.duration() - deck.current.seek()) * 1000,0))
      setTimeLeft(_timeLeft)
      if(Math.abs(_timeLeft - timeLeft) > 250) { //Sync threshold
        setSongEndTime(Date.now() + _timeLeft)
        
      }
      
    }
  }

  useEffect(() => {
    const timeout = setTimeout(refreshClock,100)
    return () => clearTimeout(timeout)
  },[currTrack,tic])

  useEffect(() => {
    if(timeLeft < 1000 && !isInitializing){
      console.log('startTransitionTrack')
      currIsB ? setPlayDeckA(true) : setPlayDeckB(true)
      console.log('timeLeft: ' + timeLeft)
      if(timeLeft < 250 && !roundEnded){
        console.log('FLIP DECKS')
        console.log('currIsB: ' + currIsB)
        currIsB ? setPlayDeckB(false) : setPlayDeckA(false)
        setCurrTrack(copy(nextTrack))
        setCurrIsB(toggle => !toggle)
        setRoundEnded(true)
      }
    } else if (timeLeft > 30000 && isInitializing){
      setIsInitializing(false)
      console.log('stop initializing')
    }
  },[timeLeft, isInitializing, currIsB])

  return (
    <View>
      <ReactHowler
        //src='https://firebasestorage.googleapis.com/v0/b/fluid-unfolding-125308.appspot.com/o/sparkLib%2FaudioFiles%2F001D06C0-D4B9-4C03-8F8C-7A1805B6A2D1.m4a?alt=media&token=743c9e22-1134-478c-b232-fc6e48b4e1a7'
        src={trackAUrl || silence}
        ref={deckA}
        playing={playDeckA}
        onEnd={() => setPlayDeckA(false)}
  
      />
      {trackBUrl && <ReactHowler
        src={trackBUrl || silence}
        ref={deckB}
        playing={playDeckB}
        onEnd={() => setPlayDeckB(false)}
      
      />}
    </View>
  );
};
