import { useState, useContext, useEffect } from 'react'
import { audioRef, songRef, storageRef, streamRef, playlistRef } from '../Config/Firebase'
import { GlobalContext, HostContext } from './Hooks'


const testPlaylist = [
  '0001B2BF-816E-40FA-AAEF-30734A1F9538',
  '000BEE76-ADF3-4C61-A6BA-94FB79BDE48A',
  '000DAFE1-5599-429A-A472-5C0BCF9D41D2',
  '0013EE55-866C-45F3-B85C-06E3B3CCA231',
  '001D06C0-D4B9-4C03-8F8C-7A1805B6A2D1',
  '001F0033-2038-49E1-B5E0-D451999A4DAC',
  '00240726-C5E6-4659-9B7F-EC9643E7B5F1',
  '002D9F29-5E8E-4D26-89D5-95E3D1324288',
  '00383B9C-FCD5-441F-A4EC-00973A2260BD',
  '0042C4C1-946C-45DA-9677-18FE580A0858',
  '0046179E-8AA5-4CD1-A460-7961014D463C',
  '004680CB-69AB-4ED9-9A7E-EEB6483D2022',
  '00570557-BFD7-4CD8-93F2-67BC3B22ADEB',
  '0069C7D4-349A-4B50-8553-726270A956B1'
]

export default () => {
  const { 
    songEndTime,
    setSongEndTime
  } = useContext(GlobalContext)
  
  const {
    currTrack,
    nextTrack,
    setCurrTrack,
    setNextTrack,
    setCurrTrackUrl,
    setNextTrackUrl,
    setStartCurrTrack,
    roundEnded,
    setRoundEnded,
    msg,setMsg,
    isStarting, setIsStarting,
    isInitializing, setIsInitializing
  } = useContext(HostContext)

  //If no game started create game
  const [retryCount,setRetryCount] = useState(0)
  const [playedList, setPlayedList] = useState([])
  const [choices,setChoices] = useState([])
  const [lib, setLib] = useState([])
  const [error,setError] = useState()
  const [votesEnded,setVotesEnded] = useState(false)
  const [votes,setVotes] = useState([0,0,0,0,0])
  const [roundID, setRoundID] = useState()
  const [winner, setWinner] = useState(0)
  const [playlist, setPlaylist] = useState([])
  const [timeLeft, setTimeLeft] = useState(0)
  //If game started continue

  const getChoices = () => {
    setMsg('pulling choices')
    console.log('GET CHOICES')
    if(lib.length > 5) {
      console.log('got local lib')
      pickChoices(lib)
    } else {
      console.log('getting remote lib')
      playlistRef.child('custom-playlist-thelinq-oct2019/songs-clean').once('value', snapshot => {
        console.log('got remote playlist')
        let _playlist = snapshot.val() || []

        songRef.once('value', (snapshot) => {
          console.log('got song lib')
          let _lib = parseAndMatchLib(snapshot.val(), _playlist)
          setLib(_lib)
          pickChoices(_lib)
        })
      }).catch((_error) => {
        console.log("Error retrieving library in getChoices")
        setError(_error)
      })
    }
  }

  const getRandomCount = (x,array) => {
    console.log('getRandomCount')
    console.log('x:')
    console.log(x)
    let _array = JSON.parse(JSON.stringify(array)) || []
    let shuffled = _array.sort(() => 0.5 - Math.random());
    let selected = shuffled.slice(0, x) || [];
    console.log('selected:')
    console.log(selected)
    return selected
  }

  const parseAndMatchLib = (snapshotVal,_playlist) => {

    let results = Object.entries(snapshotVal.metadata)
    let cleanLib = []
    results.forEach(entry => {
      let item = {}
      item.songID = entry[0]
      if(_playlist.indexOf(item.songID) >= 0){
        item.title = entry[1].track.name
        item.artist = entry[1].track.artists.map(artist => artist.name).join(', ')
        item.albumArtUrl = entry[1].track.album.images[0].url
        item.duration = entry[1].track.duration_ms
        cleanLib.push(item)
      }
    })
    return cleanLib
  }

  const parseVotes = snapshotVal => {
    let results = Object.entries(snapshotVal || {})
    let _votes = [0,0,0,0,0]
    
    results.forEach(entry => {
      let _vote = entry[1] || {}
      if(_vote.selected != null && _vote.selected >= 0 && _vote.selected < 5){
        _votes[_vote.selected]++
      }
    })
    console.log('parseVotes - votes')
    console.log(_votes)
    return _votes
  }

  const pickChoices = _lib => {
    console.log('pickChoices')
    let x = isStarting ? 6 : 5
    let _choices =  getRandomCount(x, _lib)
    let _winner = Math.floor(Math.random() * 5)
    setWinner(_winner)
    console.log('random winner set locally')
    if(isStarting) {
      console.log('pickChoices - isStarting')
      let _currTrack = _choices.pop()
      console.log('_currTrack:')
      console.log(_currTrack)
      postNewRound(_currTrack, _choices)
      setCurrTrack(_currTrack)
      setStartCurrTrack(true)
      setNextTrack(_choices[_winner])
      setIsStarting(false)
      //setIsInitializing(false)
    }
    setChoices(_choices)
  }
  
  const postNewRound = (_track=nextTrack,_choices=choices) => {
    console.log('postNewRound')
    console.log(_track)
    streamRef.child('fridaybeers/public').set({
      songEndTime: songEndTime,
      nowPlaying: _track,
      choices: _choices
    })
    let playedRef = streamRef.child('fridaybeers/private/played').push()
    playedRef.set({
      playID: playedRef.key,
      //roundID: ref.key,
      timestamp: Date.now(),
      track: _track
    })
    streamRef.child('fridaybeers/private/votes').remove()
  }

  const postWinner = (_winner=winner) => {
    console.log('postWinner')
    setNextTrack(choices[_winner])
    streamRef.child('fridaybeers/public').update({
      winner:_winner,
      tally: votes
    }) 
  }

  useEffect(() => {
    if(isStarting) {
      console.log('IS STARTING')
      setMsg('initializing')
      getChoices() 
    } 
  },[isStarting])

  //Listeners
  useEffect(() => {
    streamRef.child('fridaybeers/private/votes').on('value', (snapshot) => {
      let data = snapshot.val()
      console.log('streamRef - votes')
      console.log(data)
      let _votes = parseVotes(data)
      if(!votesEnded){
        setVotes(_votes)
        let maxVotes = Math.max(_votes)
        if(maxVotes > 0){ setWinner(_votes.indexOf(maxVotes)) }
      }
    })
    return () => streamRef.child('fridaybeers/private/votes').off()
  },[votesEnded])
  //Writes to the server
  
  useEffect(() => {
    if(songEndTime > 0){
      streamRef.child('fridaybeers/public').update({songEndTime:songEndTime})
    }
  },[songEndTime])

  useEffect(() => {
    if(!isInitializing){
      //console.log('not initiating')
      if(timeLeft <= 30000 && timeLeft > 0 && !votesEnded){
        console.log('VOTE ENDED')
        console.log(winner)
        setVotesEnded(true)
        postWinner()
        getChoices()
      } else if(timeLeft > 30000 && votesEnded){
        console.log('vote not ended')
        setVotesEnded(false)
      }
    }
  },[timeLeft,votesEnded,isInitializing])

  useEffect(() => {
    if(roundEnded){
      console.log('roundEnded ' + roundEnded)
      setRoundEnded(false)
      postNewRound()
    } else {
      console.log('roundEnded ' + roundEnded)
    }
  },[roundEnded, nextTrack, choices, votes])

  useEffect(() => {
    if(nextTrack && nextTrack.songID){
      console.log('getting nextTrackUrl')
      audioRef.child(nextTrack.songID + '.m4a').getDownloadURL()
        .then(url => {
          console.log('_nextTrackUrl')
          console.log(url)
          setNextTrackUrl(url)
        })
    }
  },[nextTrack])

  useEffect(() => {
    if(currTrack && currTrack.songID){
      audioRef.child(currTrack.songID + '.m4a').getDownloadURL()
      .then(url => {
        console.log('_currTrackUrl')
        console.log(url)
        setCurrTrackUrl(url)
      })
    }
  },[currTrack])

  //Clock Driver
  const refreshClock = () => {
    let _timeLeft = Math.floor(Math.max(songEndTime - Date.now(),0))
    setTimeLeft(_timeLeft)
  }

  useEffect(() => {
    const timeout = setTimeout(refreshClock,100)
    return () => clearTimeout(timeout)
  },[currTrack,songEndTime, timeLeft])

  return null
};
