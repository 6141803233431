import { View, Text, StyleSheet } from 'react-native-web'
import { useState } from 'react'
import VoteCircle from './VoteCircle'
import { vh } from '../../../Utilities/Dimensions'


const s = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%'
  }
})
const voteObj = {
  'v1234':{
      voteID: 'v1234',
      timestamp: Date.now() + 10000,
      userInitials: 'ABC',
      color: 'blue',
      avatarUrl: 'https://www.w3schools.com/howto/img_avatar.png'
  },
  'v5678': {
    voteID: 'v5678',
    timestamp: Date.now() + 5000,
    userInitials: '132',
    color: 'orange',
    avatarUrl: 'https://www.w3schools.com/howto/img_avatar2.png'
  }
}

export default () => {
  const [votes, setVotes] = useState(voteObj)

  const voteItems = Object.values(votes).map(voteData => {
    return voteData.unmount ?
      null
      :
      (<VoteCircle 
        userInitials={voteData.userInitials}
        color={voteData.color}
        avatarUrl={voteData.avatarUrl}
        key={voteData.voteID}
        voteID={voteData.voteID}
        votes={votes}
        setVotes={setVotes}
      />)
  })
  return (
    <View style={s.container}>{voteItems}</View>
  )
}