import { View, Text, StyleSheet } from 'react-native-web'
import { vh, vw } from '../../../../Utilities/Dimensions'
import { useSpring, animated, config } from 'react-spring'
import { useState, useContext, useEffect } from 'react'
import { GameboardContext, GlobalContext } from '../../../../Utilities/Hooks'
import AlbumArt from './AlbumArt'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  container: {
    //backgroundColor: 'blue',
    //flex:1
  }
})

const a ={
  albumArtContainer: {
    //backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

let albumArtUrl = 'https://okayplayer-wpengine.netdna-ssl.com/wp-content/uploads/2022/05/kendrick-lamar-mr-morale-and-the-big-steppers-album-cover.jpeg'

export default ({ isController }) => {
  const { showCD, minimize, showTimer } = useContext(GameboardContext)
  const { nowPlaying } = useContext(GlobalContext)

  const enterExitAnimation = useSpring({
    from: {
      transform: !minimize ? 'translateY(0%)' : 'translateY(200%)' 
    },
    to: {
      transform: !minimize ? 'translateY(-200%)' : 'translateY(0%)'
    },
    reverse: showTimer || minimize,
    config: config.stiff
  })

  useEffect(() => {
    console.log('nowPlaying - display')
    console.log(nowPlaying.albumArtUrl)
  },[nowPlaying])

  return (
    <View style={[s.container,{width: isController ? null : vw(40)}]}>
        <AnimatedView style={{...a.albumArtContainer,...enterExitAnimation}}>
          <AlbumArt isController={isController} albumArtUrl={nowPlaying.albumArtUrl}/>
        </AnimatedView>
    </View>
  )
}