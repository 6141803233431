import { View, StyleSheet } from "react-native-web";


const s = StyleSheet.create({
  container: {
    flex:1,
    //backgroundColor: 'red',
    flexDirection:'row'
  }
}); 

export default () => {
  return ( 
    <View style={s.container}>
 
    </View>
  )
};