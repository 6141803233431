import { useState, useEffect, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import { View, Text, StyleSheet } from 'react-native-web'
import { vh } from '../../../Utilities/Dimensions'
import { GameboardContext, GlobalContext } from '../../../Utilities/Hooks'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  clockContainer: {
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  clockText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: vh(6),
    fontVariant: ['tabular-nums']
  },
  timeLeftContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  timeLeftText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: vh(2)
  },
})

const a = {
  container: {
    position: 'absolute',
    flex:1,
    right: 0,
    top: vh(20.75),
    margin: 'auto',
    height: vh(9),
    width: vh(18)
  }
}

export default ({ showTimer, timeLeft }) => { 
  const [clock,setClock] = useState('0:00')

  const enterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(100%)'
    },
    to: {
      transform: 'translateX(0%)'
    },
    reverse: !showTimer,
  })

  useEffect(() => {
    let _timeLeftMin = parseInt((timeLeft/(1000*60))%60)
    let _timeLeftSec = parseInt((timeLeft/1000)%60)
    _timeLeftMin = ('00' + _timeLeftMin).substr(-2,2)
    _timeLeftSec = ('00' + _timeLeftSec).substr(-2,2)
    setClock(_timeLeftMin + ':' + _timeLeftSec)
  },[timeLeft])
   



  return (
    <AnimatedView style={{...a.container,...enterExitAnimation}}>
      <View style={s.timeLeftContainer}><Text style={s.timeLeftText}>TIME LEFT</Text></View>
      <View style={s.clockContainer}><Text style={s.clockText}>{clock}</Text></View>  
    </AnimatedView>
  )
}