import { createContext } from 'react'

export const GlobalContext = createContext({
  songEndTime: 0,
  setSongEndTime: () => {},
  timeLeft: 0,
  setTimeLeft: () => {},
  height:0,
  width:0,
  choices:[],
  setChoices: () => {},
  nowPlaying: {},
  setNowPlaying: () => {},
  votes:[],
  setVotes: () => {},
  winner: null,
  setWinner: () => {},
  user: null,
  setUser: () => {}
})

export const GameboardContext = createContext({
  minimize: false,
  setMinimize: () => {},
  showWinner: false,
  setShowWinner: () => {},
  showCD: true,
  setShowCD: () => {},
  showLogo: true,
  setShowLogo: () => {},
  showNoise: false, 
  setShowNoise: () => {},
  showCountdown: false,
  setShowCountdown: () => {},
  showChoices:true,
  setShowChoices: () => {},
  timeLeft: 0,
  setTimeLeft: () => {},
  votesEnded: false,
  setVotesEnded: () => {},
  showWinningChoice: false,
  setShowWinningChoice: () => {},
  roundRestart: false, 
  setRoundRestart: () => {},
  nowPlaying: {},
  setNowPlaying: () => {}
})

export const HostContext = createContext({
  startCurrTrack: false,
  setStartCurrTrack: () => {},
  startNextTrack: false,
  setStartNextTrack: () => {},
  currTrack: {},
  setCurrTrack: () => {},
  nextTrack: {},
  setNextTrack: () => {},
  winner: null,
  setWinner: () => {},
  currTrackUrl: null,
  setCurrTrackUrl: () => {},
  nextTrackUrl: null,
  setNextTrackUrl: () => {},
  roundEnded: false,
  setRoundEnded: () => {},
  isInitializing: true,
  setIsInitializing: () => {},
  isStarting: false,
  setIsStarting: () => {},
  msg: 'Starting',
  setMsg: () => {}
})