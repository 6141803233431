import { View, StyleSheet } from "react-native-web";
import Gameboard from '../Components/Gameboard'
const s = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
  }
});

export default () => {
  return (
    <View style={s.container}>
      <Gameboard />
    </View>
  );
};
