import { ImageBackground, View, Text, StyleSheet, unstable_createElement } from 'react-native-web'
import { useState } from 'react'
import { vw } from '../../Utilities/Dimensions'

const Video = (props) => unstable_createElement('video', props)

const s = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'black',
    minHeight: '100%',
		minWidth:'100%',
		width: 'auto',
		height: 'auto',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
  }
})

export default () => {
  const [source,setSource] = useState('https://firebasestorage.googleapis.com/v0/b/fluid-unfolding-125308.appspot.com/o/visuals%2FfuturisticVid.mp4?alt=media&token=6299a95c-46dc-460c-abe5-0a4a38e923b3')
  const [isVideo,setIsVideo] = useState(true)
  
  return (
    isVideo ?
      <Video src={source} style={s.container} muted autoPlay loop playsInline/>
      : 
      <ImageBackground source={source} style={s.container}>
    
      </ImageBackground>
  )
}