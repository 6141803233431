import { View, StyleSheet } from "react-native-web";
import { useState, useEffect } from 'react'
import { authRef } from '../Config/Firebase'
import Controller from '../Components/Controller'
import Auth from './Auth'

const s = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "black"
  }
});

export default () => {
  const [user, setUser] = useState()

  useEffect(() => {
    authRef.onAuthStateChanged(_user => {
      if(_user){
        setUser(_user)
      } else {
        setUser(null)
      }
    })
  },[])

  return (
    <View style={s.container}>
      {
        true ? <Controller /> : <Auth />
      }
    </View>
  )
};
