import { View, ImageBackground, StyleSheet } from 'react-native-web'
import { useSpring, animated, config } from 'react-spring'
import { useState } from 'react'
import { vw,vh } from '../../../../Utilities/Dimensions'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  albumArtImg: {
    resizeMode: 'cover',
    width: vh(50),
    height: vh(50),
    borderWidth: 5,
    borderColor: 'white',
    borderRadius: 1000,
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerDot: {
    position: 'absolute',
    borderRadius: 100,
    backgroundColor: 'white',
    height: vh(2.5),
    width: vh(2.5)
  },
  middleRing: {
    position: 'absolute',
    borderRadius: 100,
    borderWidth: 20,
    borderColor: 'white',
    height: vh(10),
    width: vh(10)
  },
  outerRing: {
    position: 'absolute',
    borderRadius: 100,
    borderWidth: 2,
    borderColor: 'white',
    height: vh(12),
    width: vh(12)
  }
})

const a = {
  container: {
    borderRadius: 1000,
    overflow: 'hidden',
    width: vh(50),
    height: vh(50)
  },
  controllerContainer: {
    borderRadius: 1000,
    overflow: 'hidden',
    width: vh(20),
    height: vh(20)  
  }
}

export default ({ albumArtUrl, isController }) => {
  const rotateAnimation = useSpring({
    loop: true,
    from: { rotateZ: 0 },
    to: { rotateZ: 360 },
    config: { duration: 4000 }
  })



  return (
    <AnimatedView style={{...isController ? a.controllerContainer :  a.container,...rotateAnimation, }}>
      <ImageBackground style={[s.albumArtImg,{width: isController ? vh(20) : vh(50),height: isController ? vh(20) : vh(50)}]} source={albumArtUrl}>
        <View style={s.centerDot} />
        <View style={s.middleRing} />
        <View style={s.outerRing} />
      </ImageBackground>
    </AnimatedView>
  )
}