import { useState, useEffect, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import { View, Text, StyleSheet } from 'react-native-web'
import { vh } from '../../../Utilities/Dimensions'
import { GameboardContext, GlobalContext } from '../../../Utilities/Hooks'

const AnimatedView = animated(View)

const s = StyleSheet.create({
  winnerContainer: {
    backgroundColor: 'gold',
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 4,
    borderRightWidth: 0,
    borderColor: 'black'
  },
  winnerText: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: vh(4),
    textAlign: 'center'
  }
})

const a = {
  container: {
    position: 'absolute',
    flex:1,
    right: 0,
    top: vh(5),
    margin: 'auto',
    height: vh(15),
    width: vh(45)
  },
  controllerContainer: {
    position: 'absolute',
    flex:1,
    right: 0,
    margin: 'auto',
    height: vh(8),
    width: '100%'
  }
}

export default ({ showWinner, isController }) => { 
  const enterExitAnimation  = useSpring({
    from: {
      transform: 'translateX(100%)'
    },
    to: {
      transform: 'translateX(0%)'
    },
    //reset: showWinner,
    reverse: !showWinner
  })

  return (
    <AnimatedView style={{...isController ? a.controllerContainer : a.container,...enterExitAnimation}}>
      <View style={s.winnerContainer}><Text style={s.winnerText}>{isController ? 'AND THE WINNER IS ...' : 'AND THE\n WINNER IS ...'}</Text></View>
    </AnimatedView>
  )
}